import '../../styles/Experience.css';
import WorkIcon from "../icons/building-solid.svg"
import { useTranslation } from "react-i18next";

function Experience() {
  const { t } = useTranslation();
  return (
    <section className="work-experience">
      <div className='work-title'>
        <img className='work-icon' src={WorkIcon} alt='Work Icon'/>
        <h2>{t('experience.heading')}</h2>
      </div>
        <div className="work-experience-wrapper">
            <div className="work">
                <h3>{t('experience.asse.role')}</h3>
                <div className="company">{t('experience.asse.company')}</div>
                <div className='duration'>{t('experience.asse.duration')}</div>
                <div className='description'>
                  {t('experience.asse.description')}
                </div>
            </div>

            <div className="work">
                <h3>{t('experience.levi9.role')}</h3>
                <div className="company">{t('experience.levi9.company')}</div>
                <div className='duration'>{t('experience.levi9.duration')}</div>
                <div className='description'>
                  {t('experience.levi9.description')}
                </div>
            </div>
        </div>
    </section>
  );
}

export default Experience;