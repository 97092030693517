import '../../styles/Skills.css';
import SkillsIcon from "../icons/toolbox-solid.svg"
import { useTranslation } from "react-i18next";

function Skills() {
  const { t } = useTranslation();
  return (
    <section className="skills">
        <div className='skills-title'>
          <img className='skills-icon' src={SkillsIcon} alt='Skills Icon'/>
          <h2>{t('skills.heading')}</h2>
        </div> 
          <div className='skill-technologies'>
            <div className='skill-technologies-wrapper'>
                <div className='skill-technology'>Java</div>
                <div className='skill-technology'>Python</div>
                <div className='skill-technology'>Kotlin</div>
                <div className='skill-technology'>C#</div>
                <div className='skill-technology'>JavaScript</div>
                <div className='skill-technology'>PHP</div>
                <div className='skill-technology'>MySQL</div>
                <div className='skill-technology'>AI</div>
                <div className='skill-technology'>Git</div>
                <div className='skill-technology'>GitHub</div>
                <div className='skill-technology'>intelliJ</div>
                <div className='skill-technology'>Visual Studio</div>
                <div className='skill-technology'>{t("skills.soft.skill1")}</div>
                <div className='skill-technology'>{t("skills.soft.skill2")}</div>
                <div className='skill-technology'>{t("skills.soft.skill3")}</div>
                <div className='skill-technology'>{t("skills.soft.skill4")}</div>
                <div className='skill-technology'>{t("skills.soft.skill5")}</div>
            </div>
          </div>
    </section>
  );
}

export default Skills;