import '../styles/Header.css';
import CodeIcon from "../code_icon.svg"
import DownloadIcon from "./icons/file-arrow-down-solid.svg"
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();
  return (
    <header className="header">
        <div className='header-body'>
            <div className='header-container'>
                <div className='header-wrapper'>
                  <div className='brend-wrapper'>
                    <img className='brand-icon' src={CodeIcon} alt='Code Icon'/>
                    <a className='brand-name' href='/'>{t('header.logo')}</a>
                  </div>
                    <div className='button-cv-wrapper'>
                      <a className='button-cv-download' target='_blank' href={t('header.download-link')}><img className='download-icon' src={DownloadIcon} alt='Download Icon'/>{t('header.download')}</a>
                    </div>
                </div>
            </div>
        </div>
    </header>
  );
}

export default Header;