import '../../styles/Certifications.css';
import CertificationIcon from "../icons/award-solid.svg"
import Link from "../icons/arrow-up-right-from-square-solid.svg"
import { useTranslation } from "react-i18next";

function Certifications() {
  const { t } = useTranslation();
  return (
    <section className="certifications">
        <div className='certifications-title'>
          <img className='certifications-icon' src={CertificationIcon} alt='Certifications Icon'/>
          <h2>{t('certifications.heading')}</h2>
        </div> 
        <div className="certifications-wrapper">
            <div className="certification">
                <h3>{t('certifications.certification1.heading')}</h3>
                <div className="name">{t('certifications.certification1.company')}</div>
                <div className='duration'>{t('certifications.certification1.date')}</div>
                <div className='description'></div>
                <div className="project-buttons">
                    <a className='github' target="_blank" href='https://www.hackerrank.com/certificates/5965680a5315'>
                        <img className='certifications-button-icon' src={Link} alt='Project Github Icon'/>
                        <p>{t('certifications.certification1.button')}</p>
                    </a>
                </div>
            </div>

            <div className="certification">
                <h3>{t('certifications.certification2.heading')}</h3>
                <div className="name">{t('certifications.certification2.company')}</div>
                <div className='duration'>{t('certifications.certification2.date')}</div>
                <div className='description'>
                
                </div>
                <div className="project-buttons">
                    <a className='github' target="_blank" href='https://www.hackerrank.com/certificates/2c3d19a389a7'>
                        <img className='certifications-button-icon' src={Link} alt='Project Github Icon'/>
                        <p>{t('certifications.certification2.button')}</p>
                    </a>
                </div>
            </div>

            <div className="certification">
                <h3>{t('certifications.certification3.heading')}</h3>
                <div className="name">{t('certifications.certification2.company')}</div>
                <div className='duration'>{t('certifications.certification2.date')}</div>
                <div className='description'></div>
                <div className="project-buttons">
                    <a className='github' target="_blank" href='https://freecodecamp.org/certification/fcc30b92c63-19a2-4d4a-8ae6-e996da2a9b00/foundational-c-sharp-with-microsoft'>
                        <img className='certifications-button-icon' src={Link} alt='Project Github Icon'/>
                        <p>{t('certifications.certification2.button')}</p>
                    </a>
                </div>
            </div>
        </div>
    </section>
  );
}

export default Certifications;