import '../../styles/Education.css';
import EducationIcon from "../icons/graduation-cap-solid.svg"
import { useTranslation } from "react-i18next";

function Education() {
  const { t } = useTranslation();
  return (
    <section className="education">
        <div className='education-title'>
          <img className='education-icon' src={EducationIcon} alt='Work Icon'/>
          <h2>{t('education.heading')}</h2>
        </div> 
        <div className="education-wrapper">
            <div className="collage">
                <h3>{t('education.bachelor.title')}</h3>
                <div className="name">{t('education.bachelor.collage')}</div>
                <div className='duration'>{t('education.bachelor.duration')}</div>
            </div>

            <div className="collage">
                <h3>{t('education.master.title')}</h3>
                <div className="name">{t('education.master.collage')}</div>
                <div className='duration'>{t('education.master.duration')}</div>
            </div>
        </div>
    </section>
  );
}

export default Education;