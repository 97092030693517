import '../../styles/AboutMe.css';
import CopyIcon from "../icons/copy-regular.svg"
import GithubIcon from "../icons/github-brands-solid.svg"
import LinkedinIcon from "../icons/linkedin-brands-solid.svg"
import ProfileImage from "../icons/profile2.png"
import {useState, useEffect}  from "react";
import { useTranslation } from "react-i18next";

function SectionIntro() {

  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(t("aboutme.email"));
  }, [i18n.language, t]);

  const handleCopy = () => {
    const textToCopy = email;

    const showMessage = (msg) => {
      setMessage(msg);
      setTimeout(() => setMessage(""), 2000);
    };

   // API
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => showMessage(t("email.copy-success")))
        .catch((err) => {
          showMessage(t("email.copy-fail"));
        });
    } else {

      // Fallback
      try {
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        textarea.style.position = "fixed";
        textarea.style.opacity = "0";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        const successful = document.execCommand("copy");
        document.body.removeChild(textarea);

        if (successful) {
          showMessage(t("email.copy-success"));
        } else {
          showMessage(t("email.copy-fail"));
        }
      } catch (err) {
        showMessage(t("email.copy-fail"));
      }
    }
  };
  
  
  return (
    <section className="aboutme">
        <div className='aboutme-wrapper'>

          <div className='aboutme-profile'>
            <div className='aboutme-profile-image'>
              <img className='profile-image' src={ProfileImage} alt='Profile Image'/>
            </div>

            <div className='aboutme-info'>
              <div className='info-title-name-wrapper'>
                <div className='name'>{t('aboutme.name')}</div>
                <div className='title'>{t('aboutme.title')}</div>
            </div>

            <div className='info-other'>
                <div className='adress'>{t('aboutme.adress')}</div>
                <div className='email' onClick={handleCopy}>{email}<img className='copy-icon' src={CopyIcon} alt='Copy Icon'/></div>
                {message && <div className="copy-message">{message}</div>}
                <div className='social-networks'>
                  <a className='github-profile' target="_blank" href='https://github.com/bogdanmaric'><img className='github-icon' src={GithubIcon} alt='Copy Icon'/></a>
                  <a className='linkedin-profile' target="_blank" href='https://www.linkedin.com/in/bogdan-maric-dev'><img className='linkedin-icon' src={LinkedinIcon} alt='Copy Icon'/></a>
                </div>
              </div>
            </div>
          </div>

        <div className='aboutme-summary'>
          <p className='aboutme-summary-text'>{t('aboutme.text')}</p>
        </div>
      </div>
    </section>
  );
}

export default SectionIntro;