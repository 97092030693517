import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer-wrapper">
        <div className='footer-text'>
            © 2023-2025 Bogdan Marić Developer. All rights reserved.
        </div>
    </footer>
  );
}

export default Footer;
