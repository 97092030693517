import '../../styles/Projects.css';
import ProjectIcon from "../icons/code-commit-solid.svg"
import Github from "../icons/github-alt-brands-solid.svg"
import { useTranslation } from "react-i18next";

function Projects() {
  const { t } = useTranslation();
  return (
    <section className="projects">
        <div className='projects-title'>
            <img className='project-icon' src={ProjectIcon} alt='Projects Icon'/>
            <h2>{t('projects.heading')}</h2>
        </div>
        <div className="projects-wrapper">


            <div className="project">
                <h3>{t('projects.project1.heading')}</h3>
                <p className='description'>
                {t('projects.project1.description')}
                </p>
                <div className='used-technologies'>
                    <div className='used-technologies-wrapper'>
                        <div className='technology'>Java</div>
                        <div className='technology'>Spring Boot</div>
                        <div className='technology'>Spring MVC</div>
                        <div className='technology'>Hibernate</div>
                        <div className='technology'>Maven</div>
                        <div className='technology'>MongoDB</div>
                        <div className='technology'>Git</div>
                    </div>
                </div>
                <div className="project-buttons">
                    <a className='github' target='_blank' href='https://github.com/bogdanmaric/spring-boot-restful-movies-api'>
                        <img className='project-button-icon' src={Github} alt='Project Github Icon'/>
                        <p>Github</p>
                    </a>
                </div>
            </div>

            <div className="project">
                <h3>{t('projects.project2.heading')}</h3>
                <p className='description'>
                    {t('projects.project2.description')}
                </p>
                <div className='used-technologies'>
                    <div className='used-technologies-wrapper'>
                        <div className='technology'>C#</div>
                        <div className='technology'>ASP.NET Core</div>
                        <div className='technology'>ASP.NET Core MVC</div>
                        <div className='technology'>NuGet</div>
                        <div className='technology'>IIS</div>
                        <div className='technology'>MS-SQL</div>
                        <div className='technology'>Git</div>
                    </div>
                </div>
                <div className="project-buttons">
                    <a className='github' target='_blank' href='https://github.com/bogdanmaric/asp-core-restful-books-api'>
                        <img className='project-button-icon' src={Github} alt='Project Github Icon'/>
                        <p>Github</p>
                    </a>
                </div>
            </div>

            <div className="project">
                <h3>{t('projects.project3.heading')}</h3>
                <p className='description'>
                    {t('projects.project3.description')}
                </p>
                <div className='used-technologies'>
                    <div className='used-technologies-wrapper'>
                        <div className='technology'>PHP</div>
                        <div className='technology'>Laravel</div>
                        <div className='technology'>Blade</div>
                        <div className='technology'>Breeze</div>
                        <div className='technology'>MySQL</div>
                    </div>
                </div>
                <div className="project-buttons">
                    <a className='github' target='_blank' href='https://github.com/bogdanmaric/laravel-ebook'>
                        <img className='project-button-icon' src={Github} alt='Project Github Icon'/>
                        <p>Github</p>
                    </a>
                </div>
            </div>

            <div className="project">
                <h3>{t('projects.project4.heading')}</h3>
                <p className='description'>
                    {t('projects.project4.description')}
                </p>
                <div className='used-technologies'>
                    <div className='used-technologies-wrapper'>
                        <div className='technology'>JavaScript</div>
                        <div className='technology'>React</div>
                        <div className='technology'>UI/UX</div>
                    </div>
                </div>
            </div>

        </div>
    </section>
  );
}

export default Projects;