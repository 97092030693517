import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import '../styles/App.css';
import Header from './Header.js';
import AboutMe from './sections/AboutMe.js';
import Experience from "./sections/Experience.js"
import Education from "./sections/Education.js"
import Projects from "./sections/Projects.js"
import Certifications from "./sections//Certifications.js"
import Skills from "./sections//Skills.js"
import Footer from "./Footer.js"

import { GeoApi } from '../Service/GeoApi.js';

function App() {
  const { i18n } = useTranslation();

    useEffect(() => {
        const hasVisited = localStorage.getItem("hasVisited");
    
        if (!hasVisited) {
            GeoApi().then((countryName) => {
            handleLanguageChange(countryName);
            localStorage.setItem("countryName", countryName);
          });
          localStorage.setItem('hasVisited', true);
        } else {
            const countryName = localStorage.getItem("countryName");
            handleLanguageChange(countryName);
        }
      }, []);

    const handleLanguageChange = (countryName) => {
        if (countryName === "Serbia") {
            i18n.changeLanguage("native");
        } else {
            i18n.changeLanguage("en");
        }
    };



  return (
    <div className="body">
      <Header />
      <div className='body-sections'>
        <AboutMe />
        <Experience />
        <Skills />
        <Projects />
        <Education />
        <Certifications />
        <Footer />
      </div>
    </div>
  );
}

export default App;
